@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite/dist/flowbite.css';
@import "@fortawesome/fontawesome-free/css/all.min.css";


body {
  background-color: #e9edc9;
}

html {
  scroll-behavior: smooth;
}